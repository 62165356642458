import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GridSortModel } from '@mui/x-data-grid';
import { isSort } from './sort.type';

type HookResult = {
  sortModel: GridSortModel;
  onSortModelChange: (model: GridSortModel) => void;
};

export function useServerSorting(sortingKeys: string[]): HookResult {
  const [params, setParams] = useSearchParams();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  useEffect(() => {
    for (const field of sortingKeys) {
      const sort = params.get(field);
      if (isSort(sort) && sortingKeys.includes(field)) {
        setSortModel([{ field, sort }]);
        return;
      }
    }

    setSortModel([]);
  }, [params, sortingKeys]);

  const onSortModelChange = (model: GridSortModel): void => {
    if (sortModel.length > 0) {
      params.delete(sortModel[0].field);
    }

    if (model.length > 0) {
      const [{ field, sort }] = model;
      if (isSort(sort)) {
        params.set(field, sort);
      }
    }

    setParams(params);
  };

  return {
    sortModel,
    onSortModelChange,
  };
}
