import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link, Stack, Typography } from '@mui/material';
import { GridFooter, GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppPaper } from '@components/AppPaper';
import { useAppTitle } from '@hooks/use-app-title';
import { Routes } from '@routes/routes';
import { createColumnFactory } from '@utils/create-column.factory';
import { formatDate } from '@utils/dates/format-date';
import { IAllocationSummary } from '@features/allocations/allocation.interface';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { useFilteredAllocations } from './filter-allocations-form/use-filtered-allocations';
import { FilterAllocationsForm } from './filter-allocations-form/FilterAllocationsForm';
import { BooleanChip } from '@utils/render-boolean';
import { CopyTableToClipboard } from '@components/CopyTableToClipboard';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createAllocationColumn = createColumnFactory<IAllocationSummary>();
const defaultSortModel: GridSortModel = [
  {
    field: 'endDate',
    sort: 'desc',
  },
];

const columns = [
  createAllocationColumn('id', {
    headerName: 'Id',
    width: 50,
    renderCell: ({ row: session }) => (
      <Link component={NavLink} to={`${Routes.orderSummaries}/${session.customerId}/${session.orderId}`}>
        {session.id}
      </Link>
    ),
  }),
  createAllocationColumn('customerFullName', {
    headerName: 'Customer',
    flex: 1,
  }),
  createAllocationColumn('customerCrn', {
    headerName: 'CRN',
    width: 160,
  }),
  createAllocationColumn('creationDate', {
    headerName: 'Created',
    width: 100,
    renderCell: ({ row }) => formatDate(row.creationDate),
  }),
  createAllocationColumn('nmhSupportType', {
    headerName: 'Type',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.nmhSupportType} optionKey="supportOptions" />,
    optionKey: 'supportOptions',
  }),
  createAllocationColumn('endDate', {
    headerName: 'End Date',
    width: 100,
    renderCell: ({ row }) => formatDate(row.endDate),
    hideSortIcons: false,
    sortable: true,
  }),
  createAllocationColumn('orderStatusId', {
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.orderStatusId} optionKey="statusOptions" />,
    optionKey: 'statusOptions',
  }),
  createAllocationColumn('hourlyRate', {
    headerName: 'Rate',
    width: 60,
    align: 'right',
    headerAlign: 'right',
  }),
  createAllocationColumn('allocated', {
    headerName: 'Allocated',
    width: 80,
    align: 'right',
    headerAlign: 'right',
  }),
  createAllocationColumn('remaining', {
    headerName: 'Remaining',
    width: 90,
    align: 'right',
    headerAlign: 'right',
  }),
  createAllocationColumn('used', {
    headerName: 'Used',
    width: 54,
    align: 'right',
    headerAlign: 'right',
  }),
  createAllocationColumn('tutorId', {
    headerName: 'Tutor',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.tutorId} optionKey="tutorOptions" />,
    optionKey: 'tutorOptions',
  }),
  createAllocationColumn('isValidated', {
    headerName: 'Validated',
    width: 80,
    renderCell: ({ row }) => <BooleanChip value={row.isValidated} />,
  }),
];

export const AllocationsPage: React.FC = () => {
  const {
    entries: sessions,
    totalEntries,
    paginationModel,
    onPaginationModelChange,
    isLoading,
  } = useServerPagination(useFilteredAllocations, PageSizeNames.Allocation);
  useAppTitle('Allocations');

  return (
    <AppPaper>
      <Typography variant="h4" component="h1">
        Allocations
      </Typography>
      <Box sx={{ mt: 1, mb: 2 }}>
        <FilterAllocationsForm />
      </Box>
      <AppDataGrid
        rows={sessions}
        columns={columns}
        sortModel={defaultSortModel}
        loading={isLoading}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        slots={{
          footer: () => (
            <Stack direction="row" sx={{ borderTop: '1px solid rgb(224, 224, 224)' }}>
              <CopyTableToClipboard rows={sessions} columns={columns} />
              <GridFooter sx={{ flexGrow: 1, border: 'none' }} />
            </Stack>
          ),
        }}
      />
    </AppPaper>
  );
};
