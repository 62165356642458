import React, { FC, useCallback, useMemo, useState } from 'react';
import { useGetAnnualSupportCharge } from './use-get-annual-support-charge';
import { IOrderAnnualSupportCharge } from '../annual-support-charge-order.interface';
import { IAnnualSupportCharge } from './annual-support-charge.interface';
import { ActionColumnKey, createColumnFactory } from '@utils/create-column.factory';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { formatDate } from '@utils/dates/format-date';
import { BooleanChip } from '@utils/render-boolean';
import { AppDataGrid } from '@components/AppDataGrid';
import { Box, Button, Stack, Typography } from '@mui/material';
import { AppModal } from '@components/AppModal';
import { SaveAnnualSupportChargeForm } from '../save-annual-support-charge-form/SaveAnnualSupportChargeForm';
import { useToggle } from '@hooks/use-toggle';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { AnnualSupportChargeElectronicInvoice } from './AnnualSupportChargeElectronicInvoice';

type Props = {
  order: IOrderAnnualSupportCharge;
};

const defaultSortModel: GridSortModel = [
  {
    field: 'numberOfSupportYear',
    sort: 'asc',
  },
];

const createAnnualSupportChargeColumn = createColumnFactory<IAnnualSupportCharge>();

export const AnnualSupportChargeList: FC<Props> = ({ order }) => {
  const { annualSupportChargeList, isLoading } = useGetAnnualSupportCharge(order.id);
  const [activeAnnualSupportChargeId, setAnnualSupportChargeId] = useState<number | null>(null);
  const [isModalOpen, toggleIsModalOpen] = useToggle();

  const handleCloseModal = useCallback((): void => {
    setAnnualSupportChargeId(null);
    toggleIsModalOpen();
  }, [toggleIsModalOpen]);

  const handleEditAnnualCharge = useCallback(
    (id: number | null) => {
      setAnnualSupportChargeId(id);
      toggleIsModalOpen();
    },
    [toggleIsModalOpen],
  );

  const activeAnnualSupportCharge = useMemo(
    () => annualSupportChargeList.find((item) => item.id === activeAnnualSupportChargeId),
    [activeAnnualSupportChargeId, annualSupportChargeList],
  );

  const modalTitle = activeAnnualSupportChargeId === null ? 'New Annual Support Charge' : 'Edit Annual Support Charge';

  const columns: GridColDef[] = useMemo(() => {
    return [
      createAnnualSupportChargeColumn('numberOfSupportYear', {
        headerName: 'Support Year',
        flex: 1,
        renderCell: ({ row }) => `Year ${row.numberOfSupportYear}`,
        hideSortIcons: false,
        sortable: true,
      }),
      createAnnualSupportChargeColumn('annualSupportExVat', {
        headerName: 'Net Cost (ex VAT)',
        flex: 1,
      }),
      createAnnualSupportChargeColumn('annualSupportInclVat', {
        headerName: 'Gross Cost (inc VAT)',
        flex: 1,
      }),
      createAnnualSupportChargeColumn('billingDate', {
        headerName: 'Billing Date',
        flex: 1,
        renderCell: (params) => formatDate(params.row.billingDate),
      }),

      createAnnualSupportChargeColumn('isCancelled', {
        headerName: 'Cancelled',
        flex: 1,
        renderCell: ({ row }) => <BooleanChip value={row.isCancelled} isInvertedColor />,
      }),
      createAnnualSupportChargeColumn('cancellationReason', {
        headerName: 'Cancellation Reason',
        flex: 1,
        renderCell: ({ row }) => (
          <BackendLabel value={row.cancellationReason} optionKey="ascCancellationReasonOptions" />
        ),
      }),
      createAnnualSupportChargeColumn('invoice', {
        headerName: 'Invoice',
        flex: 1,
      }),
      createAnnualSupportChargeColumn('isPaid', {
        headerName: 'Paid',
        width: 50,
        renderCell: ({ row }) => <BooleanChip value={row.isPaid} />,
      }),
      createAnnualSupportChargeColumn(ActionColumnKey, {
        headerName: 'Actions',
        width: 80,
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Button onClick={(): void => handleEditAnnualCharge(row.id)} variant="outlined" size="small">
            Edit
          </Button>
        ),
      }),
      createAnnualSupportChargeColumn('electronicInvoice', {
        headerName: 'Invoice',
        flex: 1,
        renderCell: ({ row }) =>
          row.invoice && <AnnualSupportChargeElectronicInvoice order={order} annualSupportCharge={row} />,
      }),
    ];
  }, [handleEditAnnualCharge, order]);

  return (
    <Box sx={{ marginTop: 2 }}>
      <Stack direction="row" alignItems="center" sx={{ marginBottom: 1 }}>
        <Typography variant="h5" flex={1}>
          Annual Support Charge Schedule
        </Typography>
        <Button variant="outlined" onClick={(): unknown => handleEditAnnualCharge(null)}>
          Add asc manually
        </Button>
      </Stack>
      <AppDataGrid
        sx={{ height: '320px' }}
        rows={annualSupportChargeList}
        columns={columns}
        sortModel={defaultSortModel}
        loading={isLoading}
      />
      <AppModal open={isModalOpen} onClose={handleCloseModal} title={modalTitle}>
        <SaveAnnualSupportChargeForm
          annualSupportCharge={activeAnnualSupportCharge}
          orderId={order.id}
          onClose={handleCloseModal}
        />
      </AppModal>
    </Box>
  );
};
