import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';

type ISignSessionRequest = {
  sessionId: number;
  file: File;
};

export const buildUploadTimesheet = appMutationFactory<ISignSessionRequest, void>({
  query: ({ sessionId, file }) => {
    return {
      url: EndpointUrlBuilder.uploadTimesheet(sessionId),
      method: 'PATCH',
      data: createPayload(file),
    };
  },
});

export interface ISignSessionPayload {
  nmh_session: {
    timesheet_file: File;
  };
}

function createPayload(file: File): ISignSessionPayload {
  return {
    nmh_session: {
      timesheet_file: file,
    },
  };
}

interface IRegenerateTimesheetResponse {
  newDocumentPath: string;
}

export const buildRegenerateTimesheet = appMutationFactory<number, string>({
  query: (sessionId) => ({
    url: EndpointUrlBuilder.regenerateTimesheet(sessionId),
    method: 'PATCH',
  }),
  transformResponse(response: IRegenerateTimesheetResponse) {
    return response.newDocumentPath;
  },
});
