import { useEffect } from 'react';
import { useAppDispatch } from '@store/use-app-dispatch';
import {
  GenericInvoiceSummaryActions,
  selectAllSummaries,
} from '@features/bulk-invoicing/generic-invoice-summary.slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useGetEquipmentInvoiceSummariesQuery } from '@api/api-slice';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { IPaginable } from '@features/pagination/paginable.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { IEquipmentInvoiceSummary } from '@features/bulk-invoicing/equipment/equipment-invoice-summary.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { sanitize, serialize } from './form-config';
import { useAppSelector } from '@store/use-app-selector';

type HookResult = RequestStatusFlags & IPaginable<IEquipmentInvoiceSummary>;

export function useFilteredEquipmentInvoiceSummaries(
  urlSearchParams: URLSearchParams,
  count: number,
  skip: number,
): HookResult {
  const backendLabels = useAppBackendLabels(['organisationOptions', 'orderTypeNameOptions']);
  const dispatch = useAppDispatch();

  const params = serialize(sanitize(deserializeURLSearchParams(urlSearchParams), backendLabels));
  const queryResult = useGetEquipmentInvoiceSummariesQuery(
    { ...params, count, skip, isFinance: true },
    { refetchOnMountOrArgChange: true },
  );
  const { entries, ...rest } = getPaginationResult(queryResult);

  useEffect(() => {
    if (rest.isSuccess) {
      dispatch(GenericInvoiceSummaryActions.setAll(entries));
    }
  }, [entries, dispatch, rest]);

  useEffect(() => {
    return () => {
      dispatch(GenericInvoiceSummaryActions.removeAll());
    };
  }, [dispatch]);

  const apps = useAppSelector((state) => selectAllSummaries<IEquipmentInvoiceSummary>(state));

  return { entries: apps, ...rest };
}
