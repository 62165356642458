import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { isUserDisabled } from '@features/backend-label/user-label.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { FormModel, sanitize, serialize } from './form-config';
import { BooleanOptions } from '@utils/boolean-options';

export const FilterSessionsForm: React.FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels(['tutorOptions', 'organisationOptions']);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm({
    values: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(filterEmptyObjectFields(serialize(data)) as unknown as Record<string, string>));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledDatePicker control={control} name="startDate" label="Start Date" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledDatePicker control={control} name="endDate" label="End Date" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="tutorId"
            label="Tutor"
            options={backendLabels.tutorOptions}
            getOptionDisabled={isUserDisabled}
            isLoading={isLoading}
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete control={control} name="isPaid" label="Is Paid" options={BooleanOptions} />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="organisationId"
            label="Organisation"
            options={backendLabels.organisationOptions}
            isLoading={isLoading}
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="funderInvoice" label="Funder Invoice" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="tutorInvoice" label="Tutor Invoice" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledTextField control={control} name="sessionId" label="Session Id" />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="signedByStudent"
            label="Signed By Student"
            options={BooleanOptions}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <ControlledAutocomplete
            control={control}
            name="signedByTutor"
            label="Signed By Tutor"
            options={BooleanOptions}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <Button type="reset" variant="outlined" onClick={onReset} fullWidth>
            Reset
          </Button>
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <Button type="submit" variant="contained" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
