import moment, { Moment } from 'moment-timezone';
import { IAllocation } from '@features/allocations/allocation.interface';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = Pick<IAllocation, 'nmhSupportType' | 'hourlyRate' | 'allocated' | 'tutorId' | 'isValidated'> & {
  endDate: Moment | null;
};

export type ApiModel = Pick<
  IAllocation,
  'nmhSupportType' | 'hourlyRate' | 'allocated' | 'tutorId' | 'isValidated' | 'endDate'
>;

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  nmhSupportType: { type: 'backend-label', optionKey: 'supportOptions', initial: null },
  hourlyRate: { type: 'transportable', initial: 0 },
  allocated: { type: 'transportable', initial: 0 },
  endDate: { type: 'date', initial: moment() },
  tutorId: { type: 'backend-label', optionKey: 'tutorOptions', initial: null },
  isValidated: { type: 'transportable', initial: false },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  nmhSupportType: Serializers.backendValue,
  hourlyRate: Serializers.float,
  allocated: Serializers.float,
  tutorId: Serializers.backendValue,
  isValidated: Serializers.boolean,
  endDate: Serializers.date,
});
