import React, { useCallback, useMemo, useState } from 'react';
import { ActionColumnKey, createColumnFactory } from '@utils/create-column.factory';
import { AppDataGrid } from '@components/AppDataGrid';
import { Button, Stack, Typography } from '@mui/material';
import { IQuoteDetail } from '@features/quote/quote.interface';
import { AppModal } from '@components/AppModal';
import { CopyTableToClipboard } from '@components/CopyTableToClipboard';
import { useToggle } from '@hooks/use-toggle';
import { IProductSummary } from '../product.interface';
import { TotalId, createTotalRow } from './create-total-row';

type EditableProps = {
  products: IProductSummary[];
  quote: IQuoteDetail | null;
  onDeleteProduct: (id: number) => unknown;
  isEditable?: boolean;
  isCompact?: boolean;
};

type NonEditableProps = {
  products: IProductSummary[];
  quote: IQuoteDetail | null;
  onDeleteProduct?: never;
  isEditable: false;
  isCompact?: boolean;
};

type Props = EditableProps | NonEditableProps;

const createProductColumn = createColumnFactory<IProductSummary>();

const BaseColumns = [
  createProductColumn('productName', {
    headerName: 'Item',
    flex: 1,
  }),
  createProductColumn('quantity', {
    headerName: 'Quantity',
    width: 80,
  }),
  createProductColumn('productCode', {
    headerName: 'Product Code',
    flex: 1,
  }),
  createProductColumn('productCodeAs', {
    headerName: 'Product code AS',
    flex: 1,
  }),
  createProductColumn('productCodeSst', {
    headerName: 'Product code SST',
    flex: 1,
  }),
  createProductColumn('amountExVat', {
    headerName: 'Ex VAT',
    width: 80,
  }),
  createProductColumn('amountInclVat', {
    headerName: 'Inc VAT',
    width: 80,
  }),
];

export const ProductTable: React.FC<Props> = ({
  products,
  quote,
  onDeleteProduct,
  isEditable = true,
  isCompact = false,
}) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [productId, setProductId] = useState<number | null>(null);

  const handleDeleteProduct = useCallback(
    (value: number): void => {
      setProductId(value);
      toggleIsModalOpen();
    },
    [toggleIsModalOpen],
  );

  const handleConfirm = useCallback((): void => {
    toggleIsModalOpen();
    if (productId && onDeleteProduct) {
      onDeleteProduct(productId);
    }
    setProductId(null);
  }, [onDeleteProduct, productId, toggleIsModalOpen]);

  const columns = useMemo(() => {
    if (isEditable) {
      return BaseColumns.concat(
        createProductColumn(ActionColumnKey, {
          headerName: 'Actions',
          width: 100,
          align: 'right',
          headerAlign: 'right',
          renderCell: ({ row }) =>
            row.id !== TotalId && (
              <Button onClick={(): void => handleDeleteProduct(row.id)} variant="outlined" size="small" color="error">
                Delete
              </Button>
            ),
        }),
      );
    }
    return BaseColumns;
  }, [handleDeleteProduct, isEditable]);

  const enhancedWithTotal = quote ? products.concat(createTotalRow(quote)) : products;

  return (
    <>
      <Typography variant="h5" sx={{ mt: 4 }}>
        Quote Products
      </Typography>
      <AppDataGrid
        rows={enhancedWithTotal}
        columns={columns}
        sx={{ mt: 1 }}
        autoHeight={isCompact}
        fullHeight={!isCompact}
        hideFooter={isCompact}
        hideFooterPagination={!isCompact}
        slots={{
          footer: () => (isCompact ? null : <CopyTableToClipboard rows={products} columns={columns} />),
        }}
      />
      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Delete Quote Product?">
        <>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Are you sure you want to delete this Quote Product?
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={handleConfirm} color="error">
              Delete
            </Button>
            <Button variant="text" onClick={toggleIsModalOpen}>
              Keep
            </Button>
          </Stack>
        </>
      </AppModal>
    </>
  );
};
