import React from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { ModalChildProps } from '@components/AppModal';
import { isUserDisabled } from '@features/backend-label/user-label.interface';
import { OrderCancelledWarning } from '@features/order/shared/cancel-or-restore-order/OrderCancelledWarning';
import { CancelOrRestoreOrder } from '@features/order/shared/cancel-or-restore-order/CancelOrRestoreOrder';
import { useSaveOrderForm } from '../use-save-order-form';
import { sanitize, serialize } from './form-config';
import { IOrderNew } from './order-new.interface';

type Props = {
  order: IOrderNew;
} & ModalChildProps;

export const OrderNew: React.FC<Props> = ({ order, onClose }) => {
  const {
    control,
    submitHandler,
    isButtonDisabled,
    operatorOptions,
    orderTypeNameOptions,
    isDropdownsLoading,
    statusOptions,
  } = useSaveOrderForm(order, sanitize, serialize, onClose);

  const isNewOrder = !order.id;
  const buttonText = isNewOrder ? 'Create Order' : 'Save Order';

  return (
    <Box>
      <OrderCancelledWarning orderId={order.id} position="top" />
      <form onSubmit={submitHandler}>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="userId"
              label="Operator"
              options={operatorOptions}
              getOptionDisabled={isUserDisabled}
              isLoading={isDropdownsLoading}
            />
          </Grid>

          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="orderTypeId"
              label="Order Type"
              options={orderTypeNameOptions}
              isLoading={isDropdownsLoading}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledFileField
              fullWidth
              control={control}
              name="acceptanceLetterFile"
              label="Upload Acceptance"
              fileDetails={order.acceptanceFilePath}
              buttonLabel="Preview Acceptance"
              inputProps={{
                accept: '.pdf',
              }}
              isPreview
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="orderStatusId"
              options={statusOptions}
              label="Order Status"
              isLoading={isDropdownsLoading}
              groupBy={(option): string => option.orderType}
            />
          </Grid>
          <Grid item xs={4} md={8} lg={12}>
            <ControlledTextField control={control} name="notes" label="Notes" multiline minRows={3} fullWidth />
          </Grid>

          <Grid item xs={4} md={8} lg={12}>
            <Stack direction="row" spacing={2}>
              {!isNewOrder && <CancelOrRestoreOrder orderId={order.id} />}
              <Button sx={{ flexGrow: 1 }} variant="contained" size="large" type="submit" disabled={isButtonDisabled}>
                {buttonText}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <OrderCancelledWarning orderId={order.id} position="bottom" />
    </Box>
  );
};
