import { Moment } from 'moment-timezone';

type MonthRange = {
  from: string;
  to: string;
};

export function getMonthRange(fromMoment: Moment, now: Moment): MonthRange {
  const isThisMonth = now.get('month') === fromMoment.get('month');
  const from = (isThisMonth ? now : fromMoment).format('YYYY-MM-DD');
  const to = fromMoment.clone().endOf('month').format('YYYY-MM-DD');
  return { from, to };
}
