import React from 'react';
import moment from 'moment-timezone';
import { Box, Button, Grid, Stack } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { Allocations } from '@features/allocations/Allocations';
import { OrderCancelledWarning } from '@features/order/shared/cancel-or-restore-order/OrderCancelledWarning';
import { CancelOrRestoreOrder } from '@features/order/shared/cancel-or-restore-order/CancelOrRestoreOrder';
import { isUserDisabled } from '@features/backend-label/user-label.interface';
import { useSaveOrderForm } from '../use-save-order-form';
import { IOrderNmh } from './order-nmh.interface';
import { sanitize, serialize } from './form-config';

type Props = {
  order: IOrderNmh;
};

export const OrderNMH: React.FC<Props> = ({ order }) => {
  const {
    control,
    submitHandler,
    isButtonDisabled,
    operatorOptions,
    statusOptions,
    levelOfStudyOptions,
    intensityOptions,
    isDropdownsLoading,
    organisationOptions,
  } = useSaveOrderForm(order, sanitize, serialize);

  const isCourseEnded = moment().isAfter(order.courseEndDate);
  const isCreationDisabled = !order.levelOfStudy || !order.intensity || !order.courseEndDate || isCourseEnded;

  return (
    <Box>
      <OrderCancelledWarning orderId={order.id} position="top" />
      <form onSubmit={submitHandler}>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="userId"
              options={operatorOptions}
              getOptionDisabled={isUserDisabled}
              label="Operator"
              isLoading={isDropdownsLoading}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="orderStatusId"
              options={statusOptions}
              label="Order Status"
              isLoading={isDropdownsLoading}
              groupBy={(option): string => option.orderType}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledFileField
              fullWidth
              control={control}
              name="acceptanceLetterFile"
              label="Upload Acceptance"
              fileDetails={order.acceptanceFilePath}
              buttonLabel="Preview Acceptance"
              inputProps={{
                accept: '.pdf',
              }}
              isPreview
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="levelOfStudy"
              options={levelOfStudyOptions}
              label="Level of Study"
              isLoading={isDropdownsLoading}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="intensity"
              options={intensityOptions}
              label="Intensity"
              isLoading={isDropdownsLoading}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="organisationId"
              options={organisationOptions}
              label="Organisation"
              isLoading={isDropdownsLoading}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledDatePicker control={control} name="courseEndDate" label="Course End Date" disablePast />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledDatePicker control={control} name="equipmentDeliveryDate" label="Equipment Delivery Date" />
          </Grid>
          <Grid item xs={4} md={8} lg={12}>
            <ControlledTextField control={control} name="notes" label="Notes" multiline minRows={3} fullWidth />
          </Grid>
          <Grid item xs={4} md={8} lg={12}>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <CancelOrRestoreOrder orderId={order.id} />
              <Button sx={{ flexGrow: 1 }} variant="contained" size="large" type="submit" disabled={isButtonDisabled}>
                Save Order
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <OrderCancelledWarning orderId={order.id} position="bottom" />
      <Allocations orderId={order.id} isDisabled={isCreationDisabled} endDate={order.courseEndDate} />
    </Box>
  );
};
