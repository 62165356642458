import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { ModelWithId } from './model-with-id.type';

const invoiceSummaryAdapter = createEntityAdapter<ModelWithId>({
  selectId: (entry) => entry.id,
});

const genericInvoiceSummarySlice = createSlice({
  name: 'Generic Invoice Summary',
  initialState: invoiceSummaryAdapter.getInitialState(),
  reducers: {
    setAll: invoiceSummaryAdapter.setAll,
    updateOne: invoiceSummaryAdapter.updateOne,
    updateMany: invoiceSummaryAdapter.updateMany,
    removeAll: invoiceSummaryAdapter.removeAll,
  },
});

export const GenericInvoiceSummaryActions = genericInvoiceSummarySlice.actions;
export const genericInvoiceSummaryReducer = genericInvoiceSummarySlice.reducer;
export type GenericInvoiceSummaryActionsType = typeof GenericInvoiceSummaryActions;

const { selectAll } = invoiceSummaryAdapter.getSelectors((state: RootState) => state.genericInvoiceSummaries);

export const selectAllSummaries = <T extends ModelWithId>(state: RootState): T[] => selectAll(state) as T[];

export const selectSummariesByIds = <T extends ModelWithId>(state: RootState, ids: number[]): T[] =>
  selectAllSummaries<T>(state).filter((item) => ids.includes(item.id));
