import React from 'react';
import moment from 'moment-timezone';
import { Button, Stack } from '@mui/material';
import { ModalChildProps } from '@components/AppModal';
import { ControlledDateTimePicker } from '@components/inputs/ControlledDateTimePicker';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { isUserDisabled } from '@features/backend-label/user-label.interface';
import { WhyDisabled } from '@components/WhyDisabled';
import { ISODateString } from '@utils/dates/iso-string.type';
import { AssessmentMethodOptions, AssessmentMethods } from '../../assessment-method.type';
import { IAppointment } from '../appointment.interface';
import { useSaveAppointmentForm } from './use-save-appointment-form';
import { useFilteredAssessors } from './use-filtered-assessors';

type Props = {
  appointment: IAppointment | void;
  orderId: number;
  endDate: ISODateString | null;
} & ModalChildProps;

export const SaveAppointmentForm: React.FC<Props> = ({ appointment, orderId, endDate, onClose }) => {
  const {
    submitHandler,
    buttonText,
    control,
    watch,
    assessmentCentreOptions,
    appointmentsCancellationReasonOptions,
    isLabelsLoading,
    isCancelled,
    isInvoiceDisabled,
    isDisabled,
    whyDisabledReason,
  } = useSaveAppointmentForm(appointment, orderId, onClose);
  const { assessorOptions, isLoading: isAssessorLoading } = useFilteredAssessors(orderId);

  const endDateMoment = moment(endDate);

  const assessmentMethod = watch('assessmentMethod');
  const isFaceToFace = assessmentMethod === AssessmentMethods.FaceToFace;
  const assessmentCentreId = watch('assessmentCentreId');
  const hasAssessmentCentre = isFaceToFace && assessmentCentreId !== null;

  return (
    <form onSubmit={submitHandler}>
      <Stack direction="column" spacing={2}>
        <ControlledAutocomplete
          control={control}
          name="assessmentMethod"
          label="Assessment Mode"
          rules={{ required: 'Assessment Mode is required' }}
          options={AssessmentMethodOptions}
          required
        />
        {isFaceToFace && (
          <ControlledAutocomplete
            control={control}
            name="assessmentCentreId"
            label="Assessment Centre"
            rules={{ required: 'Assessment Centre is required' }}
            options={assessmentCentreOptions}
            isLoading={isLabelsLoading}
            required
          />
        )}
        <ControlledAutocomplete
          control={control}
          name="assessorId"
          label="Assessor"
          rules={{ required: 'Assessor is required' }}
          options={assessorOptions}
          getOptionDisabled={isUserDisabled}
          filterPredicate={(option): boolean =>
            hasAssessmentCentre ? option.assessmentCentreIds.includes(assessmentCentreId) : true
          }
          isLoading={isAssessorLoading}
          noOptionsText="No Assessors for chosen Assessment Mode"
          required
        />
        <ControlledDateTimePicker
          control={control}
          name="offeredDate"
          label="Offered Date Time"
          minutesStep={5}
          maxDate={endDateMoment}
          required
        />
        <ControlledDateTimePicker
          control={control}
          name="appointmentDate"
          label="Appointment Date Time"
          minutesStep={5}
          maxDate={endDateMoment}
          required
        />
        <ControlledToggleButton
          control={control}
          name="isCancelled"
          getLabel={(value: boolean): string => (value ? 'Cancelled' : 'Not Cancelled')}
          fullWidth
        />
        {isCancelled && (
          <ControlledDateTimePicker
            control={control}
            name="cancellationDate"
            label="Cancellation Date Time"
            minutesStep={5}
            required
          />
        )}
        {isCancelled && (
          <ControlledAutocomplete
            control={control}
            name="cancellationReason"
            options={appointmentsCancellationReasonOptions}
            label="Cancellation Reason"
            isLoading={isLabelsLoading}
            required
            rules={{ required: 'Cancellation Reason is required' }}
          />
        )}
        <WhyDisabled disabled={isInvoiceDisabled} title="Cancelled more than 24 hours ahead. Cannot be charged.">
          <ControlledTextField
            control={control}
            name="funderInvoice"
            label="Funder Invoice"
            disabled={isInvoiceDisabled}
            fullWidth
          />
        </WhyDisabled>
        <WhyDisabled disabled={isInvoiceDisabled} title="Cancelled more than 24 hours ahead. Cannot be charged.">
          <ControlledTextField
            control={control}
            name="supplierInvoice"
            label="Supplier Invoice"
            disabled={isInvoiceDisabled}
            fullWidth
          />
        </WhyDisabled>
        <WhyDisabled disabled={isDisabled} title={whyDisabledReason}>
          <Button variant="contained" size="large" type="submit" fullWidth disabled={isDisabled}>
            {buttonText}
          </Button>
        </WhyDisabled>
      </Stack>
    </form>
  );
};
