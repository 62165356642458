import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { WhyDisabled } from '@components/WhyDisabled';
import { AppModal } from '@components/AppModal';
import { SaveAllocationForm } from './save-allocation-form/SaveAllocationForm';
import { IAllocation } from '@features/allocations/allocation.interface';
import { ActionColumnKey, createColumnFactory } from '@utils/create-column.factory';
import { formatDate } from '@utils/dates/format-date';
import { useAllocations } from './use-allocations';
import { ISODateString } from '@utils/dates/iso-string.type';
import { Sessions } from '../sessions/Sessions';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { BooleanChip } from '@utils/render-boolean';
import { useToggle } from '@hooks/use-toggle';

type Props = {
  orderId: number;
  isDisabled: boolean;
  endDate: ISODateString | null;
};

const createAllocationColumn = createColumnFactory<IAllocation>();
const defaultSortModel: GridSortModel = [
  {
    field: 'endDate',
    sort: 'desc',
  },
];

export const Allocations: React.FC<Props> = ({ orderId, isDisabled, endDate }) => {
  const { supportOptions } = useAppBackendLabels(['supportOptions']);
  const { allocations, isLoading } = useAllocations(orderId);
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [activeAllocationId, setActiveAllocationId] = useState<number | null>(null);
  const [activeType, setActiveType] = useState<string | null>(null); // TODO change to number

  const handleCloseModal = useCallback((): void => {
    setActiveAllocationId(null);
    toggleIsModalOpen();
  }, [toggleIsModalOpen]);

  const handleEditAllocation = useCallback(
    (id: number | null) => {
      setActiveAllocationId(id);
      toggleIsModalOpen();
    },
    [toggleIsModalOpen],
  );

  const filteredAllocations = useMemo(
    // TODO change to qualification
    () => (activeType === null ? allocations : allocations.filter((item) => item.nmhSupportType === activeType)),
    [allocations, activeType],
  );

  const columns = useMemo(
    () => [
      createAllocationColumn('id', { headerName: 'Id', width: 50 }),
      createAllocationColumn('endDate', {
        headerName: 'End Date',
        width: 100,
        renderCell: ({ row }) => formatDate(row.endDate),
        hideSortIcons: false,
        sortable: true,
      }),
      createAllocationColumn('nmhSupportType', {
        headerName: 'Type',
        flex: 1,
        renderCell: ({ row }) => <BackendLabel value={row.nmhSupportType} optionKey="supportOptions" />,
      }),
      createAllocationColumn('hourlyRate', { headerName: 'Rate', width: 60, align: 'right', headerAlign: 'right' }),
      createAllocationColumn('allocated', {
        headerName: 'Allocated',
        width: 80,
        align: 'right',
        headerAlign: 'right',
      }),
      createAllocationColumn('remaining', {
        headerName: 'Remaining',
        width: 90,
        align: 'right',
        headerAlign: 'right',
      }),
      createAllocationColumn('tutorId', {
        headerName: 'Tutor',
        flex: 1,
        renderCell: ({ row }) => <BackendLabel value={row.tutorId} optionKey="tutorOptions" />,
      }),
      createAllocationColumn('isValidated', {
        headerName: 'Validated',
        width: 80,
        renderCell: ({ row }) => <BooleanChip value={row.isValidated} />,
      }),
      createAllocationColumn(ActionColumnKey, {
        headerName: 'Actions',
        width: 80,
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Button onClick={(): void => handleEditAllocation(row.id)} variant="outlined" size="small">
            Edit
          </Button>
        ),
      }),
    ],
    [handleEditAllocation],
  );

  const activeAllocation = useMemo(
    () => allocations.find((allocation) => allocation.id === activeAllocationId),
    [activeAllocationId, allocations],
  );

  const modalTitle = activeAllocationId === null ? 'New Allocation' : 'Edit Allocation';

  function handleRowClick({ row }: GridRowParams<IAllocation>): void {
    setActiveAllocationId(row.id);
  }

  const uniqueSupportTypes = useMemo(
    () => Array.from(new Set(allocations.map((a) => a.nmhSupportType))),
    [allocations],
  );
  const visibleTypes = supportOptions.filter((type) => uniqueSupportTypes.includes(type.value));

  const isSessionsVisible = activeAllocationId !== null && activeAllocation && !isModalOpen;

  const isRemains = activeAllocation && activeAllocation.remaining !== null && activeAllocation.remaining <= 0;
  const isSessionCreationDisabled =
    !activeAllocation || moment().isAfter(activeAllocation.endDate) || isRemains || isDisabled;

  return (
    <Box sx={{ marginTop: 2 }}>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 1 }}>
        <Typography variant="h5">Allocations</Typography>
        <ToggleButtonGroup
          color="primary"
          value={activeType}
          exclusive
          onChange={(_, newType): void => setActiveType(newType)}
          aria-label="Platform"
          size="small"
          sx={{ flexGrow: 1 }}
        >
          {visibleTypes.map((option) => (
            <ToggleButton value={option.value} key={option.value} size="small">
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <WhyDisabled
          title="First set Intensity, Level of Study and Course End Date sometime in the future"
          disabled={isDisabled}
        >
          <Button
            variant="outlined"
            size="small"
            disabled={isDisabled}
            onClick={(): unknown => handleEditAllocation(null)}
            startIcon={<AddIcon />}
          >
            Create Allocation
          </Button>
        </WhyDisabled>
      </Stack>
      <AppDataGrid
        sx={{ height: '320px' }}
        rows={filteredAllocations}
        columns={columns}
        sortModel={defaultSortModel}
        onRowClick={handleRowClick}
        loading={isLoading}
      />
      <AppModal open={isModalOpen} onClose={handleCloseModal} title={modalTitle}>
        <SaveAllocationForm
          allocation={activeAllocation}
          orderId={orderId}
          onClose={handleCloseModal}
          endDate={endDate}
        />
      </AppModal>
      {isSessionsVisible && (
        <Sessions
          allocation={activeAllocation}
          isDisabled={isSessionCreationDisabled}
          endDate={activeAllocation.endDate}
        />
      )}
    </Box>
  );
};
