import React from 'react';
import { Typography } from '@mui/material';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useBulkSendEquipmentInvoiceMutation } from '@api/api-slice';
import { useBulkUpdate } from '../use-bulk-update';
import { GenericInvoiceSummaryActions } from '@features/bulk-invoicing/generic-invoice-summary.slice';
import { equipmentInvoiceColumnsWithResult } from './equipment-invoice-columns';
import { useSelectedEquipmentInvoiceSummaries } from './filter-equipment-invoice-summaries/use-selected-equipment-invoice-summaries';

type Props = {
  selectedIds: number[];
};

export const SendEquipmentEInvoices: React.FC<Props> = ({ selectedIds }) => {
  const rows = useSelectedEquipmentInvoiceSummaries(selectedIds);
  const { handleSubmit, rowsWithResult, isLoading } = useBulkUpdate(
    useBulkSendEquipmentInvoiceMutation,
    GenericInvoiceSummaryActions.updateMany,
    rows,
  );

  const handleClick = (): void => {
    handleSubmit({ ids: selectedIds });
  };

  return (
    <>
      <Typography variant="h4" component="h1">
        Send E-Invoices
      </Typography>
      <AppLoadingButton isLoading={isLoading} onClick={handleClick}>
        Send E-Invoices
      </AppLoadingButton>
      <AppDataGrid
        rows={rowsWithResult}
        columns={equipmentInvoiceColumnsWithResult}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter
      />
    </>
  );
};
