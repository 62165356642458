import React from 'react';
import { Box, Button, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { isUserDisabled } from '@features/backend-label/user-label.interface';
import { Lines } from '@features/order/shared/order-lines/Lines';
import { OrderElectronicInvoice } from '@features/order/shared/order-electronic-invoice/OrderElectronicInvoice';
import { GenerateSalesOrderId } from '@features/order/shared/generate-sales-order-id/GenerateSalesOrderId';
import { GenerateInvoiceNumber } from '@features/order/shared/generate-invoice-number/GenerateInvoiceNumber';
import { useQuoteLineProducts } from '@features/order/shared/order-lines/use-quote-line-products';
import { SystemOptions } from '@features/order/shared/system.type';
import { InstallationOptions } from '@features/order/shared/installation.type';
import { OrderQuote } from '@features/quote/OrderQuote';
import { MoneyPattern } from '@utils/validation-rules';
import { OrderCancelledWarning } from '@features/order/shared/cancel-or-restore-order/OrderCancelledWarning';
import { CancelOrRestoreOrder } from '@features/order/shared/cancel-or-restore-order/CancelOrRestoreOrder';
import { ImmyBotLink } from '@features/immy-bot-link/ImmyBotLink';
import { useSaveOrderForm } from '../use-save-order-form';
import { IOrderTechnical } from './order-technical.interface';
import { sanitize, serialize } from './form-config';

type Props = {
  order: IOrderTechnical;
};

export const OrderTechnical: React.FC<Props> = ({ order }) => {
  const {
    control,
    submitHandler,
    isButtonDisabled,
    operatorOptions,
    statusOptions,
    warrantyOptions,
    organisationOptions,
    isDropdownsLoading,
  } = useSaveOrderForm(order, sanitize, serialize);
  const { products, handleNotPostedProducts, currentTab, setCurrentTab } = useQuoteLineProducts(
    order.equipmentQuoteLines,
  );

  return (
    <Box>
      <OrderCancelledWarning orderId={order.id} position="top" />
      <form onSubmit={submitHandler}>
        <Box>
          <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete
                control={control}
                name="userId"
                options={operatorOptions}
                getOptionDisabled={isUserDisabled}
                label="Operator"
                isLoading={isDropdownsLoading}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete
                control={control}
                name="orderStatusId"
                options={statusOptions}
                label="Order Status"
                isLoading={isDropdownsLoading}
                groupBy={(option): string => option.orderType}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledFileField
                fullWidth
                control={control}
                name="acceptanceLetterFile"
                label="Upload Acceptance"
                fileDetails={order.acceptanceFilePath}
                buttonLabel="Preview Acceptance"
                inputProps={{
                  accept: '.pdf',
                }}
                isPreview
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete
                control={control}
                name="organisationId"
                options={organisationOptions}
                label="Warehouse"
                isLoading={isDropdownsLoading}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete
                control={control}
                name="warranty"
                options={warrantyOptions}
                label="Warranty"
                isLoading={isDropdownsLoading}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h5" component="h2" sx={{ mb: 1 }}>
            Equipment Details
          </Typography>
          <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledToggleButton
                fullWidth
                control={control}
                name="isContributePayable"
                getLabel={(value: boolean): string =>
                  value ? 'DSA Contribution Payable' : 'DSA Contribution Not Payable'
                }
              />
            </Grid>

            <Grid item xs={4} md={4} lg={4}>
              <ControlledTextField
                control={control}
                name="dsa2ApprovedAmount"
                label="DSA 2 Approved Amount"
                rules={{
                  pattern: MoneyPattern,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item xs={4} md={4} lg={4}>
              <ControlledTextField control={control} name="quoteId" label="Quote ID" />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <OrderQuote orderId={order.id} quoteId={order.quoteId} isReadonly />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledFileField
                fullWidth
                control={control}
                name="quotationFile"
                label="Upload Quotation"
                fileDetails={order.quotationFilePath}
                buttonLabel="Preview Quotation"
                inputProps={{
                  accept: '.pdf',
                }}
                isPreview
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledToggleButton
                fullWidth
                control={control}
                name="isUpgradedEquipment"
                getLabel={(value: boolean): string => (value ? 'Equipment Upgraded' : 'Equipment Not Upgraded')}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledTextField
                control={control}
                name="upgradePrice"
                label="Upgrade Price"
                rules={{
                  pattern: MoneyPattern,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledTextField control={control} name="paymentLink" label="Payment Link" />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ mb: 1 }}>
            <Typography variant="h5" component="h2">
              Delivery and Installation Details
            </Typography>
          </Stack>
          <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
            <Grid item xs={4} md={4} lg={4}>
              <Stack direction="row" spacing={2}>
                <ControlledTextField
                  control={control}
                  name="salesId"
                  label="Sales ID"
                  fullWidth={false}
                  sx={{ flexGrow: 1 }}
                />
                <GenerateSalesOrderId order={order} handleNotPostedProducts={handleNotPostedProducts} />
              </Stack>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <Stack direction="row" spacing={2}>
                <ControlledTextField
                  control={control}
                  name="invoiceNumber"
                  label="Invoice Number"
                  fullWidth={false}
                  sx={{ flexGrow: 1 }}
                />
                <GenerateInvoiceNumber order={order} />
                <OrderElectronicInvoice order={order} />
              </Stack>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledDatePicker control={control} name="pickUp" label="Pick Up Date" disablePast />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledDatePicker control={control} name="arranged" label="Arranged Date" disablePast />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete control={control} name="system" options={SystemOptions} label="System" />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete
                control={control}
                name="installationType"
                options={InstallationOptions}
                label="Installation Type"
              />{' '}
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ImmyBotLink order={order} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <ControlledTextField control={control} name="notes" label="Notes" multiline minRows={3} fullWidth />
        </Box>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <CancelOrRestoreOrder orderId={order.id} />
          <Button sx={{ flexGrow: 1 }} variant="contained" size="large" type="submit" disabled={isButtonDisabled}>
            Save Order
          </Button>
        </Stack>
      </form>
      <OrderCancelledWarning orderId={order.id} position="bottom" />
      <Lines order={order} products={products} currentTab={currentTab} setCurrentTab={setCurrentTab} />
    </Box>
  );
};
