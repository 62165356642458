import React, { useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useBulkUpdate } from '../use-bulk-update';
import { appointmentInvoiceColumnsWithResult } from './appointment-invoice-columns';
import { GenericInvoiceSummaryActions } from '@features/bulk-invoicing/generic-invoice-summary.slice';
import { useSelectedAppointmentInvoiceSummaries } from './filter-appointment-invoice-summaries/use-selected-appointment-invoice-summaries';
import { useBulkAddAppointmentSupplierInvoiceMutation } from '@api/api-slice';

type Props = {
  selectedIds: number[];
};

export const AddAppointmentSupplierInvoice: React.FC<Props> = ({ selectedIds }) => {
  const rows = useSelectedAppointmentInvoiceSummaries(selectedIds);
  const { handleSubmit, rowsWithResult, isLoading } = useBulkUpdate(
    useBulkAddAppointmentSupplierInvoiceMutation,
    GenericInvoiceSummaryActions.updateMany,
    rows,
  );
  const [supplierInvoice, setSupplierInvoice] = useState('');

  const handleClick = (): void => {
    handleSubmit({ ids: selectedIds, supplierInvoice });
  };

  return (
    <>
      <Typography variant="h4" component="h1">
        Add Supplier Invoice
      </Typography>
      <Stack direction="row" spacing={2}>
        <TextField
          variant="outlined"
          value={supplierInvoice}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            setSupplierInvoice(event.target.value);
          }}
        />
        <AppLoadingButton isLoading={isLoading} disabled={supplierInvoice === ''} onClick={handleClick}>
          Add Invoice
        </AppLoadingButton>
      </Stack>
      <AppDataGrid
        rows={rowsWithResult}
        columns={appointmentInvoiceColumnsWithResult}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter
      />
    </>
  );
};
