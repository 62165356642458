import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { createColumnFactory } from '@utils/create-column.factory';
import { Routes } from '@routes/routes';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { BackendLabelGroup } from '@features/backend-label/BackendLabelGroup';
import { IOrderSummary } from './order-summary.interface';
import { Delay } from './Delay';
import { BooleanChip } from '@utils/render-boolean';

const createOrderSummaryColumn = createColumnFactory<IOrderSummary>();

export const orderSummaryColumns = [
  createOrderSummaryColumn('customerId', {
    width: 100,
    headerName: 'IDs',
    description: 'CustomerId/OrderId',
    renderCell: ({ row: summary }) => (
      <Link key={summary.id} component={NavLink} to={`${Routes.orderSummaries}/${summary.customerId}/${summary.id}`}>
        {summary.customerId}/{summary.id}
      </Link>
    ),
    sortable: false,
    hideSortIcons: true,
  }),
  createOrderSummaryColumn('customerFullName', {
    headerName: 'Customer',
    flex: 1,
    sortable: false,
    hideSortIcons: true,
  }),
  createOrderSummaryColumn('orderTypeId', {
    headerName: 'Type',
    flex: 1,
    renderCell: ({ row: summary }) => <BackendLabel value={summary.orderTypeId} optionKey="orderTypeNameOptions" />,
    optionKey: 'orderTypeNameOptions',
    sortable: false,
    hideSortIcons: true,
  }),
  createOrderSummaryColumn('organisationId', {
    headerName: 'Organisation',
    flex: 1,
    renderCell: ({ row: summary }) => <BackendLabel value={summary.organisationId} optionKey="organisationOptions" />,
    optionKey: 'organisationOptions',
    sortable: false,
    hideSortIcons: true,
  }),
  createOrderSummaryColumn('customerCrn', { headerName: 'CRN', width: 120, sortable: false, hideSortIcons: true }),
  createOrderSummaryColumn('customerEmail', { headerName: 'Email', flex: 1, sortable: false, hideSortIcons: true }),
  createOrderSummaryColumn('customerPhone', { headerName: 'Phone', width: 120, sortable: false, hideSortIcons: true }),
  createOrderSummaryColumn('customerPostcode', {
    headerName: 'Postcode',
    width: 80,
    sortable: true,
    hideSortIcons: false,
    // sortable: false,
    // hideSortIcons: true,
  }),
  createOrderSummaryColumn('orderStatusId', {
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row: summary }) => <BackendLabel value={summary.orderStatusId} optionKey="statusOptions" />,
    optionKey: 'statusOptions',
    sortable: false,
    hideSortIcons: true,
  }),
  createOrderSummaryColumn('labels', {
    headerName: 'Labels',
    flex: 1,
    renderCell: ({ row: summary }) => <BackendLabelGroup labels={summary.labels} optionKey="labelOptions" />,
    optionKey: 'labelOptions',
    sortable: false,
    hideSortIcons: true,
  }),
  createOrderSummaryColumn('daysInLastStatus', {
    headerName: 'Delay',
    width: 80,
    renderCell: ({ row: summary }) => <Delay delay={summary.daysInLastStatus} orderStatusId={summary.orderStatusId} />,
    sortable: true,
    hideSortIcons: false,
  }),
  createOrderSummaryColumn('isCancelled', {
    headerName: 'Cancelled',
    width: 80,
    renderCell: ({ row: summary }) => <BooleanChip value={summary.isCancelled} isInvertedColor />,
    sortable: false,
    hideSortIcons: true,
  }),
  createOrderSummaryColumn('extraMile', {
    headerName: 'Extra Mile',
    width: 80,
    renderCell: ({ row: summary }) => <BooleanChip value={summary.extraMile} isInvertedColor />,
    sortable: false,
    hideSortIcons: true,
  }),
];
