import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { TransportableModel } from '@models/transportable.type';
import { IAnnualSupportChargeInvoiceSummary } from '../annual-support-charge-invoice-summary.interface';
// import { validateFilterEquipmentInvoiceSummary } from './spec/validate-equipment-invoice-summaries.spec';
import { ApiModel } from './form-config';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type AnnualSupportChargeInvoiceSummariesResponse = IPaginable<IAnnualSupportChargeInvoiceSummary>;
export const buildGetAnnualSupportChargeInvoiceSummaries = appQueryFactory<
  QueryParams,
  AnnualSupportChargeInvoiceSummariesResponse
>({
  query: (params) => ({
    url: EndpointUrlBuilder.getAnnualSupportChargeInvoiceSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: AnnualSupportChargeInvoiceSummariesResponse) {
    // validateFilterEquipmentInvoiceSummary(response, EndpointUrlBuilder.getEquipmentInvoiceSummaries());
    validatePagination(response, EndpointUrlBuilder.getEquipmentInvoiceSummaries());
    return response;
  },
});
