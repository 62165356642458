import { UseFormReturn, useForm } from 'react-hook-form';
import { useSaveProductMutation } from '@api/api-slice';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { isErrorResponse } from '@api/error-response.interface';
import { EmptyBackendLabelOptions } from '@features/backend-label/backend-labels.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { collectChangedValues } from '@utils/collect-changed-values';
import { IProductWithCondition } from '../product.interface';
import { ProductsActions } from '../product.slice';
import { FormModel, sanitize, serialize } from './form-config';

type HookResult = {
  submitHandler: React.FormEventHandler;
} & UseFormReturn<FormModel> &
  RequestStatusFlags;

export function useSaveProductForm(product: IProductWithCondition, onClose: () => void): HookResult {
  const defaultValues = sanitize(product, EmptyBackendLabelOptions);
  const useFormResult = useForm({ defaultValues });
  const { handleSubmit, setError } = useFormResult;
  const [saveMutation, flags] = useSaveProductMutation();
  const dispatch = useAppDispatch();

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const handleFormSubmit = async (data: FormModel): Promise<IProductWithCondition | void> => {
    const serializedData = serialize(data);
    const changedData = collectChangedValues(serializedData, defaultValues);

    const payload = {
      id: product.id,
      payload: {
        ...changedData,
      },
    };

    const result = await saveMutation(payload).unwrap();

    if (isErrorResponse(result)) {
      errorHandler(result);
      return;
    }

    dispatch(ProductsActions.upsertProduct(result.product));

    onClose();
  };

  const submitHandler = handleSubmit(handleFormSubmit);

  return {
    ...useFormResult,
    ...flags,
    submitHandler,
  };
}
