import { Describe, StructError, assert, boolean, enums, nullable, number, object, string } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { IProduct } from '@features/product/product.interface';
import { ProductsResponse } from '../api';
import { TrafficLights } from '@models/traffic-light.type';

const ProductStruct: Describe<IProduct> = object({
  id: number(),
  productCode: string(),
  productCategoryId: nullable(number()),
  productCodeAs: nullable(string()),
  productCodeSst: nullable(string()),
  productName: string(),
  isActive: boolean(),
  amountInclVat: number(),
  amountExVat: number(),
  vatTreatment: nullable(string()),
  trafficLight: nullable(enums(Object.values(TrafficLights))),
  feedbackAverage: number(),
});

export function validateProducts(response: ProductsResponse, url: string): void {
  for (const entry of response.entries) {
    try {
      assert(entry, ProductStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
