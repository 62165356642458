import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { FormModel, sanitize, serialize } from './form-config';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { NumberPattern } from '@utils/validation-rules';
import { OrderClasses } from '@features/order/order-class.type';

export const FilterAnnualSupportChargeInvoiceSummaries: React.FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels(['orderTypeNameOptions']);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm<FormModel>({
    values: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  // Resets form values on "all" quick filter tab click
  useEffect(() => {
    if (params.size === 0) {
      reset();
    }
  }, [params.size, reset]);

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(stringifyObjectFields(filterEmptyObjectFields(serialize(data)))));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1.5} columns={{ xs: 4, md: 8, lg: 12 }} justifyContent="flex-end">
        <Grid item xs={4} md={4} lg={3}>
          <ControlledDatePicker control={control} name="billingDateFrom" label="Billing Date From" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledDatePicker control={control} name="billingDateTo" label="Billing Date To" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledAutocomplete
            control={control}
            name="equipmentTypeId"
            label="Equipment Type"
            options={backendLabels.orderTypeNameOptions}
            isLoading={isLoading}
            filterPredicate={(option): boolean => option.orderClass === OrderClasses.Technical}
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledTextField
            control={control}
            name="numberOfSupportYear"
            label="Support Year"
            inputProps={{ inputMode: 'numeric' }}
            rules={{ pattern: NumberPattern }}
          />
        </Grid>

        <Grid item xs={4} md={4} lg={3}>
          <ControlledTextField control={control} name="funderInvoice" label="Funder Invoice" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <ControlledTextField control={control} name="dipReference" label="DIP Reference" />
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <Button type="reset" variant="outlined" size="medium" onClick={onReset} fullWidth>
            Reset
          </Button>
        </Grid>
        <Grid item xs={4} md={4} lg={3}>
          <Button type="submit" variant="contained" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
