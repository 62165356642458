import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useEquipmentInvoices } from './filter-equipment-invoice-form/use-equipment-invoices';
import { GridFooter } from '@mui/x-data-grid';
import { createColumnFactory } from '@utils/create-column.factory';
import { IEquipmentInvoice } from './equipment-invoice.interface';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useAppTitle } from '@hooks/use-app-title';
import { Routes } from '@routes/routes';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { renderEnum } from '@utils/enum/render-enum';
import { QuoteStatusOptions } from '@features/quote/quote-status.type';
import { AppPaper } from '@components/AppPaper';
import { AppDataGrid } from '@components/AppDataGrid';
import { FilterEquipmentInvoice } from './filter-equipment-invoice-form/FilterEquipmentInvoice';
import { CopyTableToClipboard } from '@components/CopyTableToClipboard';
import { EmptyCell } from '@utils/empty-cell';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createEquipmentInvoiceColumn = createColumnFactory<IEquipmentInvoice>();

const columns = [
  createEquipmentInvoiceColumn('customerId', {
    width: 100,
    headerName: 'IDs',
    description: 'CustomerId/OrderId',
    renderCell: ({ row: summary }) => (
      <Link key={summary.id} component={NavLink} to={`${Routes.orderSummaries}/${summary.customerId}/${summary.id}`}>
        {summary.customerId}/{summary.id}
      </Link>
    ),
  }),
  createEquipmentInvoiceColumn('dipReference', {
    headerName: 'Dip Reference',
    width: 130,
  }),
  createEquipmentInvoiceColumn('organisationId', {
    headerName: 'Warehouse',
    width: 150,
    renderCell: ({ row }) => <BackendLabel value={row.organisationId} optionKey="organisationOptions" />,
    optionKey: 'organisationOptions',
  }),
  createEquipmentInvoiceColumn('quoteId', {
    headerName: 'Quote number',
    width: 110,
  }),
  createEquipmentInvoiceColumn('status', {
    headerName: 'Status',
    width: 100,
    renderCell: ({ row }) => renderEnum(row.status, QuoteStatusOptions),
  }),
  createEquipmentInvoiceColumn('exVat', {
    headerName: 'Quote Ex VAT',
    width: 100,
  }),
  createEquipmentInvoiceColumn('inclVat', {
    headerName: 'Quote Inc VAT',
    width: 100,
  }),
  createEquipmentInvoiceColumn('isContributePayable', {
    headerName: 'DSA Contribution Payable',
    width: 200,
    renderCell: ({ row }) => <Box>{row.isContributePayable ? `£200` : EmptyCell}</Box>,
  }),
  createEquipmentInvoiceColumn('upgradePrice', {
    headerName: 'Upgrade Price',
    width: 150,
  }),
  createEquipmentInvoiceColumn('invoiceNumber', {
    headerName: 'Invoice number',
    width: 150,
  }),
];

export const EquipmentInvoicingPage: FC = () => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = useServerPagination(
    useEquipmentInvoices,
    PageSizeNames.EquipmentInvoice,
  );
  useAppTitle('Equipment Invoicing');

  return (
    <AppPaper>
      <Typography variant="h4" component="h1">
        Equipment Invoice
      </Typography>
      <Box sx={{ mt: 1 }}>
        <FilterEquipmentInvoice />
      </Box>
      <AppDataGrid
        rows={entries}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        slots={{
          footer: () => (
            <Stack direction="row" sx={{ borderTop: '1px solid rgb(224, 224, 224)' }}>
              <CopyTableToClipboard rows={entries} columns={columns} />
              <GridFooter sx={{ flexGrow: 1, border: 'none' }} />
            </Stack>
          ),
        }}
      />
    </AppPaper>
  );
};
