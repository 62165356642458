import React, { useMemo } from 'react';
import { ISession } from './session.interface';
import { ActionColumnKey, createColumnFactory } from '@utils/create-column.factory';
import { GridColDef } from '@mui/x-data-grid';
import { formatDateTimeAndDuration } from '@utils/dates/format-date-time-and-duration';
import { BooleanChip } from '@utils/render-boolean';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { Timesheet } from './timesheet-info/Timesheet';
import { Button } from '@mui/material';
import { SessionElectronicInvoice } from './session-electronic-invoice/SessionElectronicInvoice';
import { formatDateTime } from '@utils/dates/format-date-time';

const BaseFields: Array<keyof ISession | typeof ActionColumnKey> = [
  'id',
  'startMoment',
  'duration',
  'isCancelled',
  'tutorId',
  'userId',
  'createdAt',
  'funderInvoice',
  'tutorInvoice',
  'isPaid',
  'feedbackAvgSummary',
  'timesheetInfo',
  'electronicInvoice',
  ActionColumnKey,
];

const ExtendedFields = BaseFields.concat('offeredDate', 'cancellationDate', 'cancellationReason');

function createFilter(isExtended: boolean): (item: GridColDef) => boolean {
  const Fields = isExtended ? ExtendedFields : BaseFields;
  return (item: GridColDef): boolean => Fields.includes(item.field as keyof ISession);
}

const createSessionColumn = createColumnFactory<ISession>();

export function useFilteredSessionColumns(
  handleAction: (id: number) => void,
  isExtended: boolean,
  allocationId: number,
): GridColDef[] {
  const columns = useMemo(() => {
    const baseColumns = [
      createSessionColumn('id', { headerName: 'Id', width: 50 }),
      createSessionColumn('startMoment', {
        headerName: 'Session Date Time',
        width: 165,
        renderCell: ({ row }) => formatDateTimeAndDuration(row.startMoment, row.duration),
        hideSortIcons: false,
        sortable: true,
      }),
      createSessionColumn('duration', { headerName: 'Duration', width: 65 }),
      createSessionColumn('isCancelled', {
        headerName: 'Cancelled',
        description: 'Cancelled within 24 hours',
        width: 70,
        renderCell: ({ row }) => <BooleanChip value={row.isCancelled} isInvertedColor />,
      }),
      createSessionColumn('cancellationDate', {
        headerName: 'Cancellation Time',
        width: 124,
        renderCell: ({ row }) => formatDateTime(row.cancellationDate),
      }),
      createSessionColumn('cancellationReason', {
        headerName: 'Cancellation Reason',
        width: 140,
        renderCell: ({ row }) => (
          <BackendLabel value={row.cancellationReason} optionKey="sessionsCancellationReasonOptions" />
        ),
      }),
      createSessionColumn('tutorId', {
        headerName: 'Tutor',
        flex: 1,
        renderCell: ({ row }) => <BackendLabel value={row.tutorId} optionKey="tutorOptions" />,
      }),
      createSessionColumn('userId', {
        headerName: 'Booked by',
        flex: 1,
        renderCell: ({ row }) => <BackendLabel value={row.userId} optionKey="userOptions" />,
      }),
      createSessionColumn('offeredDate', {
        headerName: 'Offered Date',
        width: 124,
        renderCell: ({ row }) => formatDateTime(row.offeredDate),
      }),
      createSessionColumn('createdAt', {
        headerName: 'Created',
        width: 125,
        renderCell: ({ row }) => formatDateTime(row.createdAt),
      }),
      createSessionColumn('funderInvoice', {
        headerName: 'Funder Invoice',
        width: 120,
      }),
      createSessionColumn('tutorInvoice', {
        headerName: 'Tutor Invoice',
        width: 120,
      }),
      createSessionColumn('isPaid', {
        headerName: 'Paid',
        width: 60,
        renderCell: ({ row }) => <BooleanChip value={row.isPaid} />,
      }),
      createSessionColumn('feedbackAvgSummary', { headerName: 'Feedback', width: 90 }),
      createSessionColumn('timesheetInfo', {
        headerName: 'Timesheet',
        width: 225,
        renderCell: ({ row }) => <Timesheet sessionId={row.id} timesheet={row.timesheetInfo} />,
      }),
      createSessionColumn(ActionColumnKey, {
        headerName: 'Actions',
        width: 80,
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Button onClick={(): void => handleAction(row.id)} variant="outlined" size="small">
            Edit
          </Button>
        ),
      }),
      createSessionColumn('electronicInvoice', {
        headerName: 'E-Invoice',
        width: 100,
        renderCell: ({ row }) =>
          !row.timesheetInfo.isUploadAllowed && <SessionElectronicInvoice session={row} allocationId={allocationId} />,
      }),
    ];

    return baseColumns.filter(createFilter(isExtended));
  }, [allocationId, handleAction, isExtended]);

  return columns;
}
