import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link, Stack, Typography } from '@mui/material';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppPaper } from '@components/AppPaper';
import { PageSizeNames } from '@features/pagination/page-size-names';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { useAppTitle } from '@hooks/use-app-title';
import { Routes } from '@routes/routes';
import { createColumnFactory } from '@utils/create-column.factory';
import { FilterHepsForm } from './filter-heps-form/FilterHepsForm';
import { useFilteredHeps } from './filter-heps-form/use-filtered-heps';
import { HepSummary } from './hep.interface';

const createHepColumn = createColumnFactory<HepSummary>();

const columns = [
  createHepColumn('id', {
    headerName: 'Id',
    width: 45,
    renderCell: ({ row: hep }) => (
      <Link component={NavLink} to={`${Routes.heps}/${hep.id}`}>
        {hep.id}
      </Link>
    ),
  }),
  createHepColumn('name', {
    headerName: 'Name',
    flex: 1,
  }),
  createHepColumn('email', {
    headerName: 'Email',
    flex: 1,
  }),
];

export const HepListPage: FC = () => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = useServerPagination(
    useFilteredHeps,
    PageSizeNames.Heps,
  );
  useAppTitle('Heps');

  return (
    <AppPaper>
      <Stack direction="row" alignItems="center">
        <Typography variant="h4" component="h1">
          Heps
        </Typography>
        <Box sx={{ flexGrow: 1, ml: 2 }}>
          <FilterHepsForm />
        </Box>
      </Stack>
      <AppDataGrid
        rows={entries}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </AppPaper>
  );
};
