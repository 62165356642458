import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { Routes } from '@routes/routes';
import { createColumnFactory } from '@utils/create-column.factory';
import { BooleanChip } from '@utils/render-boolean';
import { renderMoney } from '@utils/money/render-money';
import { ResultCell } from '../ResultCell';
import { Result } from '../bulk-action-row-result.type';
import { IAnnualSupportChargeInvoiceSummary } from './annual-support-charge-invoice-summary.interface';
import { formatDate } from '@utils/dates/format-date';
import { BackendLabel } from '@features/backend-label/BackendLabel';

type SummaryWithResult = IAnnualSupportChargeInvoiceSummary & { result: Result | null };

const createAnnualSupportChargeInvoiceSummaryColumn = createColumnFactory<IAnnualSupportChargeInvoiceSummary>();

export const annualSupportChargeInvoiceColumns = [
  createAnnualSupportChargeInvoiceSummaryColumn('id', {
    width: 80,
    headerName: 'IDs',
    description: 'CustomerId/OrderId',
    renderCell: ({ row: summary }) => (
      <Link
        key={summary.id}
        component={NavLink}
        to={`${Routes.orderSummaries}/${summary.customerId}/${summary.orderId}`}
      >
        {summary.customerId}/{summary.orderId}
      </Link>
    ),
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('equipmentTypeId', {
    headerName: 'Equipment Type',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.equipmentTypeId} optionKey="orderTypeNameOptions" />,
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('numberOfSupportYear', {
    headerName: 'Support Year',
    width: 70,
    renderCell: ({ row }) => `Year ${row.numberOfSupportYear}`,
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('annualSupportExVat', {
    headerName: 'Annual Support Ex Vat',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.annualSupportExVat),
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('annualSupportInclVat', {
    headerName: 'Annual Support Incl Vat',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.annualSupportInclVat),
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('billingDate', {
    headerName: 'Billing Date',
    flex: 1,
    renderCell: ({ row }) => formatDate(row.billingDate),
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('isCancelled', {
    headerName: 'Cancelled',
    width: 70,
    renderCell: ({ row }) => <BooleanChip value={row.isCancelled} isInvertedColor />,
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('cancellationReason', {
    headerName: 'Cancellation Reason',
    flex: 1,
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('funderInvoice', { headerName: 'Funder Invoice', width: 110 }),
  createAnnualSupportChargeInvoiceSummaryColumn('dipReference', {
    headerName: 'DIP',
    width: 70,
  }),
  createAnnualSupportChargeInvoiceSummaryColumn('isPaid', {
    headerName: 'Paid',
    width: 70,
    renderCell: ({ row }) => <BooleanChip value={row.isPaid} />,
  }),
];

const createColumnWithResult = createColumnFactory<SummaryWithResult>();

export const annualSupportChargeInvoiceColumnsWithResult = annualSupportChargeInvoiceColumns.concat(
  createColumnWithResult('result', {
    headerName: 'Result',
    width: 100,
    renderCell: ({ row }) => <ResultCell result={row.result} />,
  }),
);
