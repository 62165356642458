import { AlertColor } from '@mui/material';
import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const TrafficLights = {
  Green: 1,
  Amber: 2,
  Red: 3,
} as const;

type Keys = keyof typeof TrafficLights;

export type TrafficLightType = (typeof TrafficLights)[Keys];

export const TrafficLightOptions = createEnumOptions(TrafficLights);

export const ColorByTrafficLight: {
  [K in TrafficLightType]: AlertColor;
} = {
  1: 'success',
  2: 'warning',
  3: 'error',
};

export const LabelByTrafficLight: {
  [K in TrafficLightType]: string;
} = {
  1: 'Green',
  2: 'Amber',
  3: 'Red',
};
