import React, { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { formatDateTime } from '@utils/dates/format-date-time';
import { BooleanChip } from '@utils/render-boolean';
import { renderEnum } from '@utils/enum/render-enum';
import { AssessmentMethodOptions } from '../assessment-method.type';
import { Button } from '@mui/material';
import { ActionColumnKey, createColumnFactory } from '@utils/create-column.factory';
import { IAppointment } from './appointment.interface';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { AppointmentElectronicInvoice } from './appointment-electronic-invoice/AppointmentElectronicInvoice';

const BaseFields: Array<keyof IAppointment | typeof ActionColumnKey> = [
  'id',
  'orderId',
  'customerId',
  'assessmentCentreId',
  'assessorId',
  'assessmentMethod',
  'appointmentDate',
  'isCancelled',
  'isPaid',
  'funderInvoice',
  'supplierInvoice',
  'electronicInvoice',
  'electronicInvoiceLogs',
  ActionColumnKey,
];

const ExtendedFields = BaseFields.concat(
  'creationDate',
  'createdByUserId',
  'offeredDate',
  'cancellationDate',
  'cancelledByUserId',
  'cancellationReason',
);

const AllowedAssessorFields: Array<keyof IAppointment | typeof ActionColumnKey> = [
  'id',
  'appointmentDate',
  'assessorId',
  'assessmentMethod',
  'isCancelled',
  'cancellationDate',
  'cancelledByUserId',
  'offeredDate',
  'creationDate',
  ActionColumnKey,
];

/**
 * Accepts two flags and return filter lambda,
 * containing logic for filtration for assessor, and base and extended fields
 */
function createFilter(isDisabled: boolean, isExtended: boolean): (item: GridColDef) => boolean {
  if (isDisabled) {
    return (item: GridColDef): boolean => AllowedAssessorFields.includes(item.field as keyof IAppointment);
  }
  const Fields = isExtended ? ExtendedFields : BaseFields;
  return (item: GridColDef): boolean => Fields.includes(item.field as keyof IAppointment);
}

const createAppointmentColumn = createColumnFactory<IAppointment>();

export function useFilteredAppointmentColumns(
  handleAction: (id: number) => void,
  isDisabledForAssessorAndQA: boolean,
  isExtended: boolean,
): GridColDef[] {
  const columns: GridColDef[] = useMemo(() => {
    const baseColumn = [
      createAppointmentColumn('id', { headerName: 'Id', width: 26 }),
      createAppointmentColumn('creationDate', {
        headerName: 'Created Date',
        width: 124,
        renderCell: ({ row }) => formatDateTime(row.creationDate),
        hideSortIcons: false,
        sortable: true,
      }),
      createAppointmentColumn('createdByUserId', {
        headerName: 'Created By',
        flex: 1,
        minWidth: 110,
        renderCell: ({ row }) => <BackendLabel value={row.createdByUserId} optionKey="userOptions" />,
      }),
      createAppointmentColumn('offeredDate', {
        headerName: 'Offered Date',
        width: 124,
        renderCell: ({ row }) => formatDateTime(row.offeredDate),
      }),
      createAppointmentColumn('appointmentDate', {
        headerName: 'Appointment Date',
        width: 124,
        renderCell: ({ row }) => formatDateTime(row.appointmentDate),
      }),
      createAppointmentColumn('assessorId', {
        headerName: 'Assessor',
        flex: 1,
        minWidth: 110,
        renderCell: ({ row }) => <BackendLabel value={row.assessorId} optionKey="assessorOptions" />,
      }),
      createAppointmentColumn('assessmentMethod', {
        headerName: 'Method',
        width: 110,
        renderCell: ({ row }) => renderEnum(row.assessmentMethod, AssessmentMethodOptions),
      }),
      createAppointmentColumn('isCancelled', {
        headerName: 'Cancelled',
        width: 80,
        renderCell: ({ row }) => <BooleanChip value={row.isCancelled} isInvertedColor />,
      }),
      createAppointmentColumn('cancellationReason', {
        headerName: 'Cancellation Reason',
        width: 140,
        renderCell: ({ row }) => (
          <BackendLabel value={row.cancellationReason} optionKey="appointmentsCancellationReasonOptions" />
        ),
      }),
      createAppointmentColumn('cancellationDate', {
        headerName: 'Cancellation Time',
        width: 124,
        renderCell: ({ row }) => formatDateTime(row.cancellationDate),
      }),
      createAppointmentColumn('cancelledByUserId', {
        headerName: 'Cancelled By User',
        width: 120,
        renderCell: ({ row }) => <BackendLabel value={row.cancelledByUserId} optionKey="userOptions" />,
      }),
      createAppointmentColumn('funderInvoice', {
        headerName: 'Funder Invoice',
        width: 110,
      }),
      createAppointmentColumn('isPaid', {
        headerName: 'Paid',
        width: 60,
        renderCell: ({ row }) => <BooleanChip value={row.isPaid} />,
      }),
      createAppointmentColumn('electronicInvoice', {
        headerName: 'E-Invoice',
        width: 100,
        renderCell: ({ row }) => <AppointmentElectronicInvoice appointment={row} />,
      }),
      createAppointmentColumn(ActionColumnKey, {
        headerName: 'Actions',
        width: isDisabledForAssessorAndQA ? 110 : 80,
        renderCell: ({ row }) => (
          <Button
            onClick={(): void => handleAction(row.id)}
            variant="outlined"
            size="small"
            disabled={isDisabledForAssessorAndQA && row.isCancelled}
          >
            {isDisabledForAssessorAndQA ? 'No Show' : 'Edit'}
          </Button>
        ),
      }),
    ];

    return baseColumn.filter(createFilter(isDisabledForAssessorAndQA, isExtended));
  }, [handleAction, isDisabledForAssessorAndQA, isExtended]);

  return columns;
}
