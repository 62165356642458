import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Stack } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { isUserDisabled } from '@features/backend-label/user-label.interface';
import { deserializeURLSearchParams } from '@utils/deserialize-url-search-params';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { FormModel, sanitize, serialize } from './form-config';

export const FilterAssessorUtilisationForm: React.FC = () => {
  const { isLoading, ...backendLabels } = useAppBackendLabels([
    'assessorOptions',
    'employmentOptions',
    'organisationOptions',
  ]);
  const [params, setParams] = useSearchParams();
  const { control, handleSubmit, reset } = useForm({
    values: sanitize(deserializeURLSearchParams(params), backendLabels),
  });

  function onSubmit(data: FormModel): void {
    setParams(new URLSearchParams(filterEmptyObjectFields(serialize(data)) as unknown as Record<string, string>));
  }

  function onReset(): void {
    reset();
    setParams({});
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={1}>
        <ControlledAutocomplete
          control={control}
          name="assessorId"
          label="Assessor"
          options={backendLabels.assessorOptions}
          getOptionDisabled={isUserDisabled}
          isLoading={isLoading}
          multiple
        />
        <ControlledAutocomplete
          control={control}
          name="employmentType"
          label="Employment"
          options={backendLabels.employmentOptions}
          isLoading={isLoading}
          multiple
        />
        <ControlledAutocomplete
          control={control}
          name="organisationId"
          label="Organisation"
          options={backendLabels.organisationOptions}
          isLoading={isLoading}
          multiple
        />
        <Button type="reset" variant="outlined" onClick={onReset} fullWidth>
          Reset
        </Button>
        <Button type="submit" variant="contained" fullWidth>
          Search
        </Button>
      </Stack>
    </form>
  );
};
