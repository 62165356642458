import { Describe, enums, nullable, number, object, string } from 'superstruct';
import { TrafficLights } from '@models/traffic-light.type';
import { IEquipmentQuoteLine } from '../equipment-quote-line.interface';

export const EquipmentQuoteLineStruct: Describe<IEquipmentQuoteLine> = object({
  id: number(),
  code: string(),
  description: string(),
  quantity: number(),
  trafficLight: nullable(enums(Object.values(TrafficLights))),
});
